import { render } from "./UserEdit.vue?vue&type=template&id=00b0be14&scoped=true"
import script from "./UserEdit.vue?vue&type=script&lang=js"
export * from "./UserEdit.vue?vue&type=script&lang=js"

import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css?vue&type=style&index=0&lang=css"
import "./UserEdit.vue?vue&type=style&index=1&id=00b0be14&lang=scss"
import "./UserEdit.vue?vue&type=style&index=2&id=00b0be14&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-00b0be14"

export default script