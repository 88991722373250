import { gql } from 'urql/core';

export default gql`
query getUser($input: GetUserInput!) {
  user(input: $input) {
    id
    email
    name
    role
  }
}
`;
