import { gql } from 'urql/core';

export default gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      name
      role
    }
  }
`;
